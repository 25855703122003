import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <section className="footer-container">
        <div className="footer-content">
          <div className="footer-brand">
            <h1 className="footer-title">CodeHarborHub</h1>
            <p className="footer-description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Praesentium, officiis a quia veniam reprehenderit ex dolores
              laudantium quod, illum quae.
            </p>
            <ul className="social-links">
              <li>
                <Link to="#" title="Twitter" className="social-link">
                  <svg
                    className="social-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="#" title="Facebook" className="social-link">
                  <svg
                    className="social-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="#" title="Instagram" className="social-link">
                  <svg
                    className="social-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                    <circle cx="16.806" cy="7.207" r="1.078"></circle>
                    <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="#" title="GitHub" className="social-link">
                  <svg
                    className="social-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974 0 4.406 2.857 8.145 6.821 9.465.499.09.679-.217.679-.481 0-.237-.008-.865-.011-1.696-2.775.602-3.361-1.338-3.361-1.338-.452-1.152-1.107-1.459-1.107-1.459-.905-.619.069-.605.069-.605 1.002.07 1.527 1.028 1.527 1.028.89 1.524 2.336 1.084 2.902.829.091-.645.351-1.084.637-1.333-2.215-.251-4.545-1.107-4.545-4.933 0-1.088.389-1.979 1.026-2.675-.103-.253-.446-1.267.099-2.64 0 0 .84-.27 2.75 1.021A9.554 9.554 0 0 1 12 6.8c.852.004 1.71.115 2.513.337 1.909-1.29 2.747-1.02 2.747-1.02.548 1.372.206 2.386.101 2.64.64.696 1.025 1.587 1.025 2.675 0 3.836-2.334 4.679-4.556 4.926.36.31.678.924.678 1.86 0 1.344-.012 2.425-.012 2.753 0 .268.178.577.684.479 3.959-1.322 6.811-5.061 6.811-9.464 0-5.509-4.465-9.974-9.974-9.974z"
                    ></path>
                  </svg>
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-links">
            <div className="footer-column">
              <p className="footer-heading">Company</p>
              <ul className="footer-list">
                <li>
                  <Link to="#" className="footer-link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Our Team
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <p className="footer-heading">Help</p>
              <ul className="footer-list">
                <li>
                  <Link to="#" className="footer-link">
                    Customer Support
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Delivery Details
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <p className="footer-heading">Resources</p>
              <ul className="footer-list">
                <li>
                  <Link to="#" className="footer-link">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Tutorials
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link to="#" className="footer-link">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-bottom">
        <div className="footer-bottom-content">
          <p className="footer-copyright">
            &copy; {new Date().getFullYear()} CodeHarborHub. All rights
            reserved.
          </p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;